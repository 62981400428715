<template>
  <div class="home">
    <Clock />
    <Mrt />
  </div>
</template>

<script>
// @ is an alias to /src
import Clock from "@/components/Clock.vue";
import Mrt from "@/components/Mrt.vue";

export default {
  name: "Home",
  components: {
    Clock,
    Mrt,
  },
};
</script>
