<template>
  <div class="nav_outer">
    <div class="gl_wrap">
      <div id="nav">
        <router-link to="/">選擇單一站別</router-link>
        <router-link to="/allStations">顯示所有站別時間</router-link>
      </div>
    </div>
  </div>
  <router-view />
</template>

<style lang="scss">
$gray: #747474;
$deep-blue: #1c1c25;
$red: #ff006a;
$green: #42b983;
$white: #cfcfcf;
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.nav_outer {
  border-bottom: 1px solid rgba($gray, 0.2);
}
#nav {
  padding: 1.5em 0em 1em;
  display: flex;
  @media screen and (max-width: 39.9375em) {
    padding: 1.5em 1em 1em 1em;
  }

  a {
    font-weight: bold;
    color: #2c3e50;
    text-decoration: none;
    margin-right: 2em;
    display: block;

    &.router-link-exact-active {
      color: $green;
      position: relative;
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background: $green;
        position: absolute;
        bottom: -1em;
        border-radius: 1em;
      }
    }
  }
}
</style>
