<script>
import { onMounted, reactive, ref, watch } from "vue";
import axios from "axios";
import jsSHA from "jssha";

export default {
  setup() {
    // radio 切換顯示的紅橘線下拉選單
    const showRedLine = ref(true);
    const toggleToRed = ref(false);

    // 所有到站時間 array
    const mrtDatas = reactive({ data: [] });

    // 紅橘線站別 array
    const stationDatas = reactive({ dataList: [] });

    // 下拉選單選到的站別 value
    const selectTrip = ref("");
    // 下拉選單選到的站別 array
    const selectedStation = ref([]);
    // render下拉選單選到的單一站別資料
    const renderData = reactive({ renderList: [] });

    // 紅線的下拉選單資料
    const redLineData = ref([]);
    // 橘線的下拉選單資料
    const orangeLineData = ref([]);

    // 取得 data 的 header function
    function GetAuthorizationHeader() {
      var AppID = "7f1da0d7442741a29d95cbda7f4d45d9";
      var AppKey = "smYjjQ6OFyU5tgzV5py5SiM7HWw";

      var GMTString = new Date().toGMTString();
      var ShaObj = new jsSHA("SHA-1", "TEXT");
      ShaObj.setHMACKey(AppKey, "TEXT");
      ShaObj.update("x-date: " + GMTString);
      var HMAC = ShaObj.getHMAC("B64");
      var Authorization =
        'hmac username="' +
        AppID +
        '", algorithm="hmac-sha1", headers="x-date", signature="' +
        HMAC +
        '"';

      return {
        Authorization: Authorization,
        "X-Date": GMTString /*,'Accept-Encoding': 'gzip'*/,
      };
    }

    // 取得各個站別data
    function getStationData() {
      axios({
        method: "get",
        url:
          "https://ptx.transportdata.tw/MOTC/v2/Rail/Metro/StationOfLine/KRTC?$top=100&$format=JSON",
        responseType: "json",
        headers: GetAuthorizationHeader(),
      }).then((res) => {
        // 所有站別data
        stationDatas.dataList = res.data;
        // console.log("所有站別data", stationDatas.dataList);
        // console.log("orange line station", stationDatas.dataList[0].Stations);
        // console.log("red line station", stationDatas.dataList[1].Stations);
        orangeLineData.value = stationDatas.dataList[0].Stations;
        redLineData.value = stationDatas.dataList[1].Stations;
      });
    }

    // 取得到站時間data
    function updateArrivalData() {
      axios({
        method: "get",
        url:
          "https://ptx.transportdata.tw/MOTC/v2/Rail/Metro/LiveBoard/KRTC?$format=JSON",
        responseType: "json",
        headers: GetAuthorizationHeader(),
      }).then((res) => {
        // console.log("到站時間data", res.data);
        mrtDatas.data = res.data;
      });
    }

    onMounted(() => {
      updateArrivalData();
      getStationData();
    });
    // TODO 顯示目前時間 及 卡片上顯示預計抵達的時間 EX: 10:42
    watch(selectTrip, (value) => {
      renderData.renderList = mrtDatas.data.filter((i) => {
        return i.StationID === value;
      });
    });

    // 依 input radio 切換顯示的紅橘線下拉選單
    watch(toggleToRed, (value) => {
      selectTrip.value = "";
      showRedLine.value = value !== "red" ? false : true;
    });

    return {
      showRedLine,
      toggleToRed,
      updateArrivalData,
      selectTrip,
      redLineData,
      orangeLineData,
      renderData,
    };
  },
};
</script>

<template>
  <div class="header">
    <div class="gl_wrap">
      <h1>MRT 高雄捷運</h1>
      <h2>選擇單一站別</h2>
    </div>
  </div>
  <div class="gl_wrap">
    <div class="flex-row select-single-trip">
      <p class="notification-orange">橘</p>
      <label class="toggle-label" for="toggle">
        <input
          class="input"
          id="toggle"
          type="checkbox"
          v-model="toggleToRed"
          value="false"
        />
        <div class="toggle-wrapper">
          <span class="selector"></span>
        </div>
        <span class="selected"></span>
      </label>
      <p class="notification-red">紅</p>
    </div>
    <div class="flex-row select-trip-wrap">
      <div v-if="toggleToRed">
        <select class="selectTrip" v-model="selectTrip">
          <option disabled selected value="">請選擇紅線站名</option>
          <option
            v-for="item in redLineData"
            :value="item.StationID"
            :key="item.StationID"
          >
            {{ item.StationName.Zh_tw }}
          </option>
        </select>
      </div>
      <div v-if="!toggleToRed">
        <select class="selectTrip" v-model="selectTrip">
          <option disabled selected value="">請選擇橘線站名</option>
          <option
            v-for="item in orangeLineData"
            :value="item.StationID"
            :key="item.StationID"
          >
            {{ item.StationName.Zh_tw }}
          </option>
        </select>
      </div>
    </div>
    <!-- selected -->

    <div class="wrap" v-if="selectTrip">
      <div
        class="card"
        v-for="item in renderData.renderList"
        :key="item.StationID"
        :class="[item.LineName.Zh_tw === '紅線' ? 'card-red' : 'card-orange']"
      >
        <div class="card-main">
          <span class="destination-staion-id">{{ item.StationID }}</span>
        </div>
        <div class="card-sub flex-row flex-align-between">
          <div class="station-wrap">
            <strong class="trip-head-sign">{{ item.TripHeadSign }}</strong>
            <div class="flex-row flex-align-center">
              <h3 class="station-name">{{ item.StationName.Zh_tw }}</h3>
            </div>
          </div>
          <div class="estimat-time">
            {{ item.EstimateTime }} <span>分</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scroped>
$gray: #747474;
$deep-blue: #1c1c25;
$red: #ff006a;
$green: #42b983;
$white: #cfcfcf;

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: $deep-blue;
  background: $white;
}
.gl_wrap {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  position: relative;
  @media screen and (max-width: 480px) {
    max-width: 100%;
  }
}

body * {
  margin: 0;
}
.header {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-end;
  padding: 1em;
  border-bottom: 1px solid rgba($gray, 0.2);
  h1 {
    color: $deep-blue;
    text-align: left;
  }
  h2 {
    color: rgba($deep-blue, 0.7);
    font-size: 0.9em;
    text-align: left;
  }
  .btn-refresh {
    font-size: 1em;
    line-height: 1em;
    border: none;
    outline: none;
    padding: 0.6em 1em;
    color: white;
    background: $green;
    position: absolute;
    bottom: 0em;
    right: 0em;
    @media screen and (max-width: 39.9375em) {
      span {
        display: none;
      }
    }
  }
}
label.toggle-label {
  pointer-events: none;
  margin: 0 0.8em;
  .input {
    display: none;
    &:checked + .toggle-wrapper {
      box-shadow: 0 8px 14px 0 rgba(darken($red, 12%), 0.12);
    }
    &:checked + .toggle-wrapper > .selector {
      left: calc(100% - 40px);
      background-color: $red;
    }
    /* &:checked ~ .notification > .selected:before {
      content: "Yes";
    } */
  }
  .toggle-wrapper {
    position: relative;
    width: 80px;
    height: 40px;
    background-color: #eaeaea;
    border-radius: 999px;
    margin: auto;
    cursor: pointer;
    pointer-events: all;
    box-shadow: 0 8px 14px 0 rgba(darken(orange, 12%), 0.12);
    .selector {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      background-color: orange;
      transition: left 0.25s ease;
      border-radius: 50%;
    }
  }
}
.select-single-trip {
  align-items: center;
  padding: 1em 0;
  @media screen and (max-width: 39.9375em) {
    padding: 1em;
  }
}
.notification-orange,
.notification-red {
  font-size: 1em;
  color: rgba($deep-blue, 0.7);
}

.margin-center {
  max-width: 480px;
  margin: 20px auto;
  div {
    margin-right: 1em;
  }
}
.wrap {
  max-width: 480px;
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.4em;
  position: relative;
  overflow: hidden;
  overflow-x: scroll;
  @media screen and (max-width: 39.9375em) {
    padding-left: 1em;
    padding-right: 1em;
  }
}

.float-wrap {
  float: left;
  width: 40000px;
  height: 132px;
}

.flex-row {
  display: flex;
}
.flex-align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}

.radio-group {
  label {
    margin-right: 1em;
    color: #fff;
    cursor: pointer;
  }
}

/* 紅線樣式 */
.card-red {
  border-left: 6px solid $red;
  .destination-staion-id {
    background: $red;
    color: #fff;
  }
}
/* 橘線樣式 */
.card-orange {
  border-left: 6px solid orange;
  .destination-staion-id {
    background: orange;
    color: #fff;
  }
}
.select-trip-wrap {
  padding: 0;
  @media screen and (max-width: 39.9375em) {
    padding: 0 1em;
  }
}
.selectTrip {
  /* margin-left: 1em;
  padding: 0.8em; */
  font-size: 1em;

  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("~@/assets/arrow-down-sign-to-navigate.svg");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.card {
  float: left;
  margin-right: 0.5em;
  height: 132px;
  width: 260px;
  background: #2f2f44;

  /* main */
  .card-main {
    display: flex;
    align-items: flex-start;
    padding: 1em;
  }
  /* sub */
  .card-sub {
    flex: 1;
    text-align: left;
    color: #fff;
    padding: 0 1em 0.4em 1em;
  }

  .station-wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  /* 紅橘線 */
  .line-name {
    font-size: 1em;
  }
  /* 站名 */
  .station-name {
    font-size: 1.6em;
    text-align: left;
    display: flex;
    align-items: center;
    margin-right: 0.4em;
  }
  .destination-staion-id {
    font-weight: bolder;
    font-size: 0.8em;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  /* 往... */
  .trip-head-sign {
    font-size: 0.6em;
    color: $gray;
  }
  /* 抵達時間 */
  .estimat-time {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: 2.4em;
    line-height: 1;
    align-self: flex-end;
    span {
      margin-bottom: 0.2em;
      margin-left: 0.8em;
      font-size: 0.4em;
      color: $gray;
    }
  }
}
</style>