
<script>
import { onMounted } from "vue";

export default {
  setup() {
    const runClock = () => {
      const hours = document.querySelector(".hours");
      const minutes = document.querySelector(".minutes");
      const seconds = document.querySelector(".seconds");

      let h = new Date().getHours();
      let m = new Date().getMinutes();
      let s = new Date().getSeconds();
      // console.log("%c==  ==", "color: yellow; font-size: 14px;", h, m, s);
      h = h < 10 ? "0" + h : h;
      m = m < 10 ? "0" + m : m;
      s = s < 10 ? "0" + s : s;

      hours.innerHTML = h;
      minutes.innerHTML = m;
      seconds.innerHTML = s;
    };

    const interval = setInterval(runClock, 1000);

    onMounted(() => {
      interval;
    });

    return {};
  },
};
</script>

<template>
  <div class="clock-wrap">
    <div class="gl_wrap">
      <h3>現在時間</h3>
      <div class="clock">
        <div class="hours">00</div>
        <div class="minutes">00</div>
        <div class="seconds">00</div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
$gray: #747474;
$deep-blue: #1c1c25;
$red: #ff006a;
$green: #42b983;
$white: #cfcfcf;

.clock-wrap {
  padding: 10em 1em 1em 1em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  h3 {
    text-align: left;
    font-size: 0.9em;
    color: rgba($deep-blue, 0.7);
  }
}
.clock {
  display: flex;
  align-items: center;
}
.hours,
.minutes,
.seconds {
  font-size: 5.6em;
  color: rgba(0, 0, 0, 0.1);
}
.minutes {
  &:before,
  &:after {
    content: ":";
    display: inline;
    font-size: 0.5em;
  }
}
</style>